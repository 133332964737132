.root {
  width: 100%;
  /* max-width: 400px; */
}

.headline {
  margin: 0 0 8px 0;
  font-weight: bold;
}

.headline:not(:first-child) {
  margin: 40px 0 8px 0;
}

.subheadline {
  color: var(--theme-color-text-faded);
  margin: 0 0 4px 0;
}

.section {
  font-size: 0.8rem;
}

.section:not(:last-child) {
  margin: 0 0 16px 0;
}

.googleMaps {
  margin: 16px 0;
}

.externalLink {
  margin: 0 0 8px 0;
}

.countryFlag {
  margin: 0 0 0 5px;
}

.button {
  color: var(--theme-color-text);
  background: none;
  border: none;
  text-align: start;
  padding: 0;
  cursor: pointer;
}

.button:hover {
  color: var(--theme-color-primary);
}

.contactAddress {
  color: var(--theme-color-text-faded);
}
