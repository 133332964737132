@use "app/style/shared.scss" as *;
@use "app/style/mixins.scss" as *;

.addCard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    @include hover-primary;
  }
}
