.formControl {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  user-select: none;

  &.noLabel {
    grid-template-columns: auto;
    gap: 0;
  }

  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  > input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
    cursor: pointer;

    color: var(--theme-color-primary);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--theme-color-text-faded);
    border-radius: 0.15em;

    display: grid;
    place-content: center;

    &:before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: center;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--theme-color-secondary);
      /* Windows High Contrast Mode */
      background-color: var(--theme-color-secondary);
    }
    &:checked {
      border-color: var(--theme-color-primary);
      &:before {
        transform: scale(1);
      }
    }
    &:disabled {
      color: var(--theme-color-text-faded);
      cursor: not-allowed;
    }
  }
}
