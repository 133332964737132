@import "app/style/mixins";
@import "app/style/shared";

.previewLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.portfolioCtaWrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
