@import "app/style/shared";
@import "app/style/mixins";


.input {
  padding-top: 0;
  font-size: 1.5rem;
}

.card {
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &.isSelected {
    @include hover-primary;
  }
}

.classPicker{
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.wordcloud {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
  max-height: 300px;
  overflow-y: scroll;
  > button {
    border-radius: 8px;
    background-color: var(--theme-color-primary);
    padding: 4px 8px;
    color: white;
    cursor: pointer;
    border: none;
    outline: none;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
