@import "app/style/shared";
@import "app/style/mixins";
.root {
  margin: 0 0 8px 0;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  width: 100%;
}

.rootBlue {
  @include gradient-primary;
}

.rootRed {
  @include gradient-red;
}

.rootGray {
  @include gradient-gray;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  color: white;
  cursor: pointer;
  flex-grow: 1;
}

.title {
  display: flex;
  align-items: center;
}

.title > svg {
  margin-left: 4px;
}

.subtitle {
  margin-top: 4px;
  font-size: 0.8rem;
  opacity: 0.8;
}

.actions {
  display: flex;
}

.actionIcon {
  color: white;
  stroke: white;
}

.actionIcon path {
  stroke: white;
}

.icon {
  color: white;
  margin-right: 8px;
  cursor: pointer;
}

.grid {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  & > * {
    flex-basis: 33%;
    min-width: 250px;
    flex: 1;
  }
}
