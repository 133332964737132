.text {
  margin-right: 4px;
  color: var(--theme-color-text-faded);
  cursor: pointer;
}

.text:hover {
  opacity: 1;
  color: var(--theme-color-primary);
}

.tooltipContent {
  max-width: 350px;
}
