@use "app/style/shared.scss" as *;

.root {
  width: 100%;
  .divider {
    background: var(--theme-color-layer-border);
    height: 2px;
    margin: 8px 0 8px 0;
    width: 100%;
  }
  .extra {
    padding: 0 4px 8px 4px;
    .hint {
      font-size: 0.8rem;
    }
  }
}

.table {
  width: 100%;
  border-spacing: 0 0;
  thead {
    background: var(--theme-color-layer-border);
  }
  th:first-child {
    border-top-left-radius: var(--theme-border-radius-small);
    border-bottom-left-radius: var(--theme-border-radius-small);
  }
  th:last-child {
    border-top-right-radius: var(--theme-border-radius-small);
    border-bottom-right-radius: var(--theme-border-radius-small);
  }
  th {
    font-size: 1.1rem;
    padding: 8px 4px;
    font-weight: normal;
    text-align: left;
  }
  td {
    padding: 4px;
  }
  tr:hover {
    padding: 4px;
    td {
      color: var(--theme-color-text) !important;
    }
  }
  tr:nth-child(even) {
    background: var(--theme-color-selected);
  }
  td:first-child {
    border-top-left-radius: var(--theme-border-radius-small);
    border-bottom-left-radius: var(--theme-border-radius-small);
  }
  td:last-child {
    border-top-right-radius: var(--theme-border-radius-small);
    border-bottom-right-radius: var(--theme-border-radius-small);
  }
}

@media (min-width: $width_xs) {
  .table {
    table-layout: fixed;
    td,
    th {
      word-wrap: break-word;
      white-space: normal;
    }
  }
}

@media (min-width: $width_md) {
  .table {
    table-layout: auto;
  }
}
