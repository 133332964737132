@import "app/style/shared";
@import "app/style/mixins";

.root {
  width: 100%;
  position: relative;
}

.item {
  margin-bottom: 8px;
}

.addCard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    @include hover-primary;
  }
}
