.base {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.opposed {
  background-color: var(--theme-color-warning);
}

.appealPending {
  background-color: var(--theme-color-secondary);
}

.refused {
  background-color: var(--theme-color-negative);
}

.registered {
  background-color: var(--theme-color-positive);
}

.published {
  background-color: var(--theme-color-positive);
}

.filed {
  background-color: var(--theme-color-primary);
}

.default {
  background-color: var(--theme-color-layer-alt);
  color: var(--theme-color-text);
}

.underExamination {
  background-color: #9c27b0;
}
