.listTitle {
  color: var(--theme-color-text-faded);
  padding: 8px;
}

.listItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: var(--theme-border-radius);
  margin: 4px 0;
  color: var(--theme-color-text-faded);
  .hasAction {
    background-color: red;
  }
}

.listItem:is(:hover, :active),
.active {
  background-color: var(--theme-color-selected);
  cursor: pointer;
  color: var(--theme-color-text);
}

.listItem svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.title {
  // font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  /* "overflow"-Wert darf nicht "visible" sein */
  text-overflow: ellipsis;
}

.textWrapper {
  width: 100%;
}

.subtitle {
  margin-top: 2px;
  font-size: 0.8rem;
  white-space: nowrap;
  color: var(--theme-color-text-faded);
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  align-items: center;
}
