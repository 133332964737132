.padding {
  padding: 16px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.isAdmin {
  border: 1px solid var(--theme-color-negative);
  border-radius: var(--theme-border-radius);
}

.isTestAccount {
  border: 1px solid var(--theme-color-secondary);
  border-radius: var(--theme-border-radius);
}
