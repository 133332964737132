.root {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 32px 16px;
}

.headline {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 16px 0;
}

.subheadline {
  margin: 0 0 16px 0;
}

/* .form>* {
    margin-bottom: 16px;
} */

.label {
  margin: 0 0 4px 4px;
  display: inline-block;
  color: var(--theme-color-text-faded);
}

.labelInvalid {
  color: var(--theme-color-negative);
}

.image {
  margin: 16px;
  max-width: 200px;
}

.button {
  width: 100%;
  margin: 16px 0 0 0;
}

.hint {
  text-align: center;
  font-size: 0.8rem;
  margin: 16px 0;
}
