@import "app/style/shared";
@import "app/style/mixins";

.root {
  margin-top: 8px;
}

.header {
  margin-bottom: 8px;
  width: 100%;
}

.listSubTitle {
  margin-top: 4px;
  color: var(--theme-color-text-faded);
}

.datawrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1;
    min-width: 300px;
  }
}

@media (min-width: $width_xs) {
  .datawrapper {
    flex-direction: column;
  }
}

@media (min-width: $width_md) {
  .datawrapper {
    flex-direction: row;
    > :first-child {
      margin-right: 8px;
    }
    > :nth-child(2) {
      margin-left: 8px;
    }
  }
}
