.euipo {
  background-color: #9575cd;
}

.dpma {
  background-color: #4db6ac;
}

.default {
  background-color: #e0e0e0;
}
