.nizza {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .dialer {
    border: 1px solid var(--theme-color-background-layer);
    padding: 8px;
    border-radius: var(--theme-border-radius);
    p {
      padding: 0;
      margin: 0 0 8px 0;
      font-size: 0.8rem;
      color: var(--theme-color-text-faded);
    }
  }
}
