.success {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
}

.headline {
  text-align: center;
}

.subheadline {
  text-align: center;
}
