.form {
  /* display: flex;
	flex-direction: column; */
}

.row {
  display: flex;
  /* justify-content: space-between; */
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row .column:not(:last-child) {
  padding-right: 20px;
}

.form select {
  margin-bottom: 8px;
  width: 100%;
}

.select {
  padding: 8px 0;
}

.selectLabel {
  display: block;
  color: var(--theme-color-text-faded);
  font-size: 0.8rem;
  margin-bottom: 6px;
}

.invoiceLocaleWrapper {
  margin: 8px 0;
}

@media only screen and (max-width: 768px) {
  .row,
  .column {
    flex-direction: column;
    width: 100%;
  }
  .row .column:not(:last-child) {
    padding-right: 0px;
  }
}
