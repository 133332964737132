.root {
  position: relative;
  animation-fill-mode: forwards !important;
}

.button {
  background: none;
  border: none;
  outline: none;
  // height: 100%;
}

.popper {
  z-index: 1000;
  animation: fadeIn 0.1s normal forwards;
  animation-fill-mode: forwards !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popperVisible {
  display: block;
}

.arrow {
  z-index: -1;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  background: transparent;
  &::before {
    content: "";
    transform: rotate(45deg);
  }
}

.arrowThemeDefault {
  &::before {
    box-shadow: var(--theme-box-shadow-menu);
    background: var(--theme-color-background);
  }
}

.arrowThemePrimary {
  &::before {
    background: var(--theme-color-primary);
  }
}

.contentWrapper {
  z-index: 3;
  border-radius: var(--theme-border-radius);
}

.contentThemeDefault {
  background: var(--theme-color-background);
}

.contentThemePrimary {
  background: var(--theme-color-primary);
}

.tooltip {
  position: relative;
  z-index: 1000;
  box-shadow: var(--theme-box-shadow-menu);
  border-radius: var(--theme-border-radius);
}

.popper[data-popper-placement^="top"] {
  .tooltip {
    .arrow {
      bottom: -4px;
    }
  }
}

.popper[data-popper-placement^="bottom"] {
  .tooltip {
    .arrow {
      top: -4px;
    }
  }
}

.popper[data-popper-placement^="left"] {
  .tooltip {
    .arrow {
      right: -4px;
    }
  }
}

.popper[data-popper-placement^="right"] {
  .tooltip {
    .arrow {
      left: -4px;
    }
  }
}

.buttonActive {
  background-color: var(--theme-color-selected);
}
