.hidden {
  display: none;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  color: var(--theme-color-text-faded);
}

.card,
.iban {
  background-color: var(--theme-color-background);
  color: var(--theme-color-text);
  border: 1px solid var(--theme-color-selected);
  border-radius: var(--theme-border-radius);
  /* font-size: 18px; */
  padding: 8px 16px;
  width: 100%;
  font-family: var(--theme-font-sans);
}

.focus {
  background-color: var(--theme-color-selected);
}

.switcher {
  display: flex;
  margin-bottom: 20px;
}

.icon {
  margin-right: 8px;
}

.active {
  border-bottom: 3px solid var(--theme-color-primary);
}

.link {
  padding: 20px;
  cursor: pointer;
  text-decoration: none;
  color: var(--theme-color-primary);
  font-weight: bold;
  /* font-size: 18px; */
  padding: 15px 20px;
  display: flex !important;
  align-items: center;
}

.link:hover {
  background: var(--theme-color-selected);
}

.hintText {
  font-size: 0.8rem;
  color: var(--theme-color-text-faded);
}

.button {
  margin-top: 32px;
}
