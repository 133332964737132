@import "app/style/shared";
@import "app/style/mixins";
.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
}

.wrapper {
  border-radius: var(--theme-border-radius);
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.crumbWrapper {
  display: flex;
  align-items: center;
}

.chevron {
  color: var(--theme-color-primary);
}

.crumbLink {
  color: var(--theme-color-primary);
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
  padding: 8px 16px;
  font-size: 1rem;
  border-radius: var(--theme-border-radius);

  .crumbIcon {
    margin-right: 8px;
  }
  &:hover {
    background-color: var(--theme-color-selected);
    text-decoration: none;
    // @include hover-primary;
  }

  &.crumbLinkNoLink {
    cursor: default;
    &:hover {
      background-color: transparent;
      // @include hover-primary;
    }
  }
}

.crumbLinkLast {
  cursor: initial;
  // background-color: var(--theme-color-selected);
}
