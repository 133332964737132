.badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 0;
}

.badges * {
  margin-right: 4px;
  margin-bottom: 4px;
}
