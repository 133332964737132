.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image {
  max-width: 250px;
  flex-basis: 60%;
  flex-shrink: 1;
}

.text {
  padding: 16px;
  min-width: 200px;
  max-width: 600px;
  text-align: center;
  margin: 0;
}

.name {
  color: var(--theme-color-primary);
  font-weight: 600;
}
