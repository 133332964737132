.root {
  border-radius: var(--theme-border-radius);
  display: flex;
  padding: 4px;
}

.button {
  border: none;
  outline: none;
  background: none;
  min-width: 32px;
  min-height: 32px;
  border-radius: var(--theme-border-radius);
  margin: 2px;
  color: var(--theme-color-text);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button:is(:hover, :active) {
  background: var(--theme-color-selected);
}

.button:is(:focus-visible) {
  border: 2px solid var(--theme-color-primary-transparent-80);
}

.buttonActive {
  cursor: initial;
  color: var(--theme-color-primary);
  background: var(--theme-color-layer);
  /* box-shadow: var(--theme-box-shadow); */
}

.buttonActive:is(:hover, :focus-visible, :active) {
  background: var(--theme-color-layer);
}
