.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  max-width: 400px;

  .buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    .button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: var(--theme-border-radius);
      color: var(--theme-color-text);
      border: none;
      outline: none;
      background: var(--theme-color-background-layer);
      &:hover {
        background: var(--theme-color-selected);
      }
      &.selected {
        background: var(--theme-color-primary);
        color: white;
      }
      &:disabled {
        cursor: initial;
        color: var(--theme-color-text-disabled);
        background: var(--theme-color-background-layer-transparent);
        opacity: 0.25;
      }
    }
  }

  .resetButton {
    margin: 8px 0 4px 0;
    margin-left: auto;
    cursor: pointer;
    :hover {
      color: var(--theme-color-primary);
    }
  }
}
