@import "app/style/shared";
@import "app/style/mixins";
.wrapper {
  margin-top: 16px;
  display: flex;
}

.right {
  > ul {
    margin: 0;
    padding: 16px 16px 16px 32px;
    color: var(--theme-color-text-light);
  }
}

.image {
  max-width: 250px;
  flex-basis: 60%;
  flex-shrink: 1;
}

.text {
  padding: 16px 16px 0px 16px;
  min-width: 200px;
  max-width: 500px;
  flex: 1;
  text-align: center;
  flex-basis: 40%;
  font-weight: 600;
  margin: 0;
  color: var(--theme-color-text-light);
}

.name {
  color: var(--theme-color-secondary);
  font-weight: 600;
}

.pricingTable {
  color: var(--theme-color-text-light);
  opacity: 0.8;
  overflow-x: auto;
}

.modal {
  @include gradient-primary;
}

.modalTitle {
  color: var(--theme-color-text-light);
}

.buttonWrapper {
  width: 100%;
  display: flex;
  margin: 16px 0 32px 0;
}

.cta {
  font-size: 1.2rem;
}

@media (min-width: $width_xs) {
  .pricingTable {
    padding: 16px;
  }
  .wrapper {
    flex-direction: column;
  }
  .left {
  }
  .right {
    flex-grow: 1;
  }
  .text {
    text-align: left;
  }
  .buttonWrapper {
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: $width_sm) {
  .wrapper {
    flex-direction: row;
  }
  .pricingTable {
    // padding: 16px 32px;
  }
  .left {
    flex-basis: 30%;
  }
  .right {
    flex-basis: 70%;
  }
  .buttonWrapper {
    align-items: center;
    justify-content: flex-end;
  }
}
