@import "app/style/shared";
@import "app/style/mixins";
.card {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .titleIcon {
    margin: auto 8px auto auto;
  }
  &:hover {
    .link {
      color: var(--theme-color-primary);
      text-decoration: none;
    }
    @include hover-primary;
  }
  h4 {
    font-size: 1.4rem;
    margin: 4px 0;
  }
  &.hasActions {
    h4 {
      padding-right: 32px;
    }
  }
  p {
    color: var(--theme-color-text-faded);
    text-overflow: ellipsis;
    margin: 4px 0;
  }
  .link {
    text-decoration: none;
    color: var(--theme-color-text);
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  .children {
    padding: 8px 16px;
    width: 100%;
    flex-grow: 1;
  }

  .actions {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .bottom {
    padding: 8px 16px;
    border-top: 1px solid var(--theme-color-border);
    color: var(--theme-color-text-faded);
    font-size: 0.8rem;
  }
}
