::-webkit-scrollbar-track {
  background-color: transparent;
  /* background-color: red; */
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 28px;
  background-color: var(--theme-color-border);
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  cursor: move;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 28px;
  background-color: var(--theme-color-text-faded);
}
