@import "app/style/shared";

.wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1;
    min-width: 300px;
  }
}

@media (min-width: $width_xs) {
  .wrapper {
    flex-direction: column;
  }
}

@media (min-width: $width_md) {
  .wrapper {
    flex-direction: row;
    > :first-child {
      margin-right: 8px;
    }
    > :nth-child(2) {
      margin-left: 8px;
    }
  }
}
