.root {
  position: relative;
  background-color: var(--theme-color-background-layer);
  border: 1px solid var(--theme-color-layer-border);
  border-radius: var(--theme-border-radius);
  padding: 16px;
  width: 100%;
  .headerWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    > :first-child {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &.headerOnly {
      margin-bottom: 0;
    }
  }
  .actionWrapper {
    display: flex;
    align-items: center;
    > * {
      margin-left: 8px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    color: var(--theme-color-text);
    .titleContent {
      display: flex;
      align-items: center;
    }
    .titleIcon {
      margin-right: 8px;
    }
    .titleWrapper {
      display: flex;
      flex-direction: column;
    }
    .title {
      font-size: 1.2rem;
    }
    .subtitle {
      color: var(--theme-color-text-faded);
      font-size: 0.8rem;
    }
  }
  .headerLink {
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: var(--theme-color-primary);
    }
  }
  .content {
    width: 100%;
    &.flex {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
