.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.name {
  font-weight: bold;
  font-size: 1.3rem;
  padding: 8px;
  text-align: center;
}

.skills {
  color: var(--theme-color-text-faded);
  padding: 8px;
}

.editButton {
  margin-top: 20px;
}

.bio {
  font-size: 1.2rem;
  font-style: italic;
  padding: 8px 4px;
  text-align: center;
}
