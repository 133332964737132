.results {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.results > * {
  margin: 0 auto 16px auto;
}

.resultList {
  width: 100%;
  height: 100%;
}

.loading {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  margin: 0 0 8px 0;
  color: var(--theme-color-text-faded);
}

.addButton {
  /* width: 100%; */
}

.addButtonChildren {
  white-space: initial;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.toolbarButtons {
  display: flex;
  align-items: center;
}

.extra {
  padding: 4px;
  font-size: 0.8rem;
  color: var(--theme-color-text-faded);
  opacity: 0.9;
}


.emptyStateWrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}