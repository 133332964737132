@import "app/style/shared";
@import "app/style/mixins";
.root {
  // @include default-transitions;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color-primary);
  color: white;
  border: none;
  padding: 0.5em 1em;
  // border-radius: 16px;
  border-radius: var(--theme-border-radius);
  white-space: nowrap;
  font-weight: 600;
  // border: 2px solid transparent;
  // @include default-transitions;
  // transition-duration: 0.1s;
  &.shadow {
    box-shadow: var(--theme-box-shadow);
  }
  &.transparentTheme {
    background-color: transparent;
    color: var(--theme-color-primary);
    box-shadow: none;
  }
  &.linkTheme {
    color: var(--theme-color-text);
    background: none;
    padding: 0;
    min-height: 0;
    box-shadow: none;
  }
  &.dangerTheme {
    background-color: var(--theme-color-negative);
    // border-color: var(--theme-color-negative);
  }
  &.warningTheme {
    background-color: var(--theme-color-warning);
    // border-color: var(--theme-color-warning);
  }
  &.positiveTheme {
    background-color: var(--theme-color-positive);
    // border-color: var(--theme-color-positive);
  }
  &.outlineTheme {
    // border-color: var(--theme-color-primary-transparent-40);
    background-color: transparent;
    color: var(--theme-color-primary);
  }
  &.whiteTheme {
    color: var(--theme-color-primary);
    background-color: white;
    // border-color: var(--theme-color-positive);
  }
}

.root:is(:hover, :active):not([disabled]) {
  cursor: pointer;
  &.themeDefault {
    filter: brightness(1.1);
  }
  &.transparentTheme {
    color: var(--theme-color-text-light);
    background-color: var(--theme-color-primary);
  }
  &.linkTheme {
    color: var(--theme-color-primary);
    text-decoration: none;
    background: none;
    box-shadow: none;
  }
  &.dangerTheme {
    background-color: var(--theme-color-negative-alt);
  }
  &.warningTheme {
    background-color: var(--theme-color-warning-alt);
  }
  &.positiveTheme {
    background-color: var(--theme-color-positive-alt);
  }
  &.outlineTheme {
    background: var(--theme-color-primary);
    color: white;
  }
  &.whiteTheme {
    filter: brightness(0.9);
  }
}
.root:disabled {
  cursor: initial;
  opacity: 0.7;
  pointer-events: none;
}

.children {
  white-space: break-spaces;
  display: flex;
  align-items: center;
  text-align: center;
  transition: none;
  user-select: none;
}

.loader {
  margin-left: 8px;
  padding: 0 !important;
  width: 16px;
  height: 16px;
}
