.button {
  color: var(--theme-color-primary);
  background-color: var(--theme-color-layer);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1em 1em;
  border-radius: var(--theme-border-radius);
  border: 1px solid var(--theme-color-layer-border);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;

  &:hover,
  :focus {
    filter: brightness(1.1);
  }

  > .icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
}
