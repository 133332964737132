.modal {
  max-width: 800px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: var(--theme-color-text);
  max-width: 400px;

  p {
    color: var(--theme-color-text-faded);
    font-size: 0.8rem;
  }
}

.heading {
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  opacity: 0.8;
  width: 100%;
}

.headingIcon {
  margin-right: 8px;
}

.dropzone {
  width: 100%;
  min-height: 200px;
}

.wrapper {
  width: 100%;
  display: block;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  padding: 8px;
  position: relative;
  /* background-color: var(--theme-color-primary-transparent-20); */
  background-color: var(--theme-color-layer-border);
  border-radius: var(--theme-border-radius);
}

.wrapperSearch {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color-selected);
  border-radius: var(--theme-border-radius);
}

.actionButtonOverlay {
  display: flex;
  margin: 8px 0;
  /* background-color: var(--theme-color-primary-transparent-20); */
}

.searchButton {
  margin: 16px 0;
  width: 100%;
}

.searchImage {
  filter: blur(2px) brightness(0.5);
  margin: 8px;
  width: auto;
  max-width: 100%;
  max-height: 300px;
  /* position: absolute; */
}

.searchUpload {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  background-color: var(--theme-color-selected);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px 8px 8px;
}

.actions > * {
  margin: 0 8px;
}
