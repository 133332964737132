.root {
    max-width: 800px;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
    .addNewButton {
        margin-top: 8px;
        &.addNewButtonInset {
            margin-left: 24px;
        }
    }
    .row:first-of-type {
        margin-top: 4px;
    }
    .row {
        $line_width: 4px;
        display: flex;
        >div:first-child {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 0 8px;
            .dot {
                flex-shrink: 0;
                // background: var(--theme-color-primary);
                border-radius: 100vh;
                width: $line_width * 2;
                height: $line_width * 2;
                border: 2px solid var(--theme-color-primary);
                // margin: 8px 0 2px 0;
            }
            .line {
                flex-grow: 1;
                width: $line_width;
                border-radius: 100vh;
                background: var(--theme-color-primary);
                margin: 0 0 8px 0;
            }
        }
        >div:nth-child(2) {
            flex-grow: 1;
            padding: 4px 0;
        }
    }
    .row:first-child {
        >div:nth-child(2) {
            flex-grow: 1;
        }
    }
    .labelWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .userNameLabel {
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 1rem;
            // color: var(--theme-color-text-faded);
            &> :first-child {
                margin-right: 8px;
            }
        }
        .dateLabel {
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 1rem;
            color: var(--theme-color-text-faded);
            margin-left: 8px;
        }
        .extraLabel {
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 1rem;
            margin-left: 16px;
            color: var(--theme-color-text-faded);
        }
    }
    .cardContent {
        padding: 8px;
    }
}