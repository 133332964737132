@import "app/style/shared";
@import "app/style/mixins";
.root {
  background-color: var(--theme-color-primary);
  border: 1px solid var(--theme-color-layer-border);
  height: 64px;
  border-radius: var(--theme-border-radius);
  display: flex;
  align-items: center;
  padding: 0 8px;
  flex-grow: 1;
  box-shadow: var(--theme-box-shadow);
}

.imageSearchButton {
  border-radius: 100vh;
  background-color: white;
  border-radius: 100vh;
  border: none;
  outline: none;
  padding: 8px 16px;
  margin-left: 8px;
  height: 42px;
  cursor: pointer;
}

.savedSearchesPopoverTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}
.savedSearchesPopoverContent {
  padding: 8px;
}

.imageSearchButton:is(:hover, :active) {
  filter: brightness(0.9);
  border: none;
}

