@import "app/style/shared";
.root {
  max-width: $width_xl;
  margin-top: 8px;
}

.paneSwitcherWrapper {
  // padding: 8px;
}

.paneSwitcher {
  width: 100%;
  display: flex;
  z-index: 2000;
  border-radius: var(--theme-border-radius);
  overflow: hidden;
  padding: 8px;
}

.paneSwitcherButton {
  background: none;
  outline: none;
  border: none;
  padding: 8px 0;
  margin: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 100px;
  flex-basis: 30%;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  color: var(--theme-color-text-faded);
  border-radius: var(--theme-border-radius);
}

.paneSwitcherButton:is(:hover, :active) {
  background: var(--theme-color-selected);
}

.paneSwitcherButtonActive {
  color: var(--theme-color-primary);
  background: var(--theme-color-selected);
}

.paneSwitcherButtonVisible {
  opacity: 1;
  pointer-events: initial;
}

.paneSwitcherButtonIcon {
  margin: 0 4px;
}

.paneSwitcherButtonTitle {
  font-size: 0.8rem;
  display: block;
  margin-top: 2px;
}

.control {
  max-width: 20rem;
}

.main {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.sidebar {
  max-width: 400px;
  flex-grow: 0;
  flex-shrink: 1;
}

.sidebarFullWidth {
  max-width: 100%;
}

@media (min-width: $width_xs) {
  .root {
    width: 100%;
  }
  .flexWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .paneSwitcher > button:nth-child(2) {
    flex-grow: 1;
  }
  .control {
    min-width: 0;
  }
  .main {
    min-width: 0;
    margin: 0 0 100px 0;
  }
  .sidebar {
    min-width: 0;
  }
}

@media (min-width: $width_md) {
  .flexWrapper {
    flex-direction: row;
  }
  .control {
    display: block;
    min-width: 300px;
  }
  .main {
    margin: 0 0 100px 16px;
  }
  .sidebar {
    min-width: 0;
  }
}

@media (min-width: $width_lg) {
  .main {
    max-width: 60rem;
    margin: 0 16px 100px 16px;
  }
  .sidebar {
    display: block;
    min-width: 300px;
    max-width: 500px;
  }
}

.mainFull {
  max-width: 100%;
  margin-right: 0;
}
