.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2px;
  max-height: 300px;
  overflow-y: auto;
  > button {
    border-radius: 8px;
    background-color: var(--theme-color-primary);
    padding: 4px 8px;
    color: white;
    cursor: pointer;
    border: none;
    outline: none;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
