@import "app/style/mixins";
@import "app/style/shared";

.root {
}

.wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  > * {
    flex: 1;
    min-width: 200px;
  }
}

.contactLine {
  margin: 0;
}

.editIcon {
  cursor: pointer;
  color: var(--theme-color-text-faded);
}

.editIcon:hover,
.editIcon:active,
.editIcon:focus {
  color: var(--theme-color-primary);
}

// @media (min-width: $width_xs) {
//   .wrapper {
//     flex-direction: column;
//   }
// }

// @media (min-width: $width_md) {
//   .wrapper {
//     flex-direction: row;
//     > :first-child {
//       margin-right: 8px;
//     }
//     > :nth-child(2) {
//       margin-left: 8px;
//     }
//   }
// }
