.react-toggle {
  color: white;
}

.react-toggle--checked .react-toggle-track {
  background-color: #f9a825;
}

.react-toggle--checked:hover .react-toggle-track {
  background-color: #f9a825 !important;
}

.react-toggle--unchecked .react-toggle-track {
  background-color: #121212;
}

.react-toggle-track-check,
.react-toggle-track-x {
  display: flex;
  justify-content: center;
  align-items: center;
}
