.react-dropdown-select-dropdown {
  border-color: var(--theme-color-border) !important;
  border-radius: var(--theme-border-radius-small) !important;
}

.react-dropdown-select-dropdown {
  background: var(--theme-color-layer) !important;
  color: var(--theme-color-text) !important;
  width: 100% !important;
}

.react-dropdown-select-item {
  min-height: 36px;
  display: flex;
  align-items: center;
}

.react-dropdown-select-input {
  color: var(--theme-color-text) !important;
  font-size: 1rem !important;
}

.react-dropdown-select-option {
  border-radius: var(--theme-border-radius-small) !important;
}

.react-dropdown-select-option-label {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
