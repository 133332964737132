.root {
  max-height: 100%;
  /* overflow-y: scroll; */
}

.subheadline {
  color: var(--theme-color-text-faded);
}

.id {
  font-size: 1.2rem;
}

.text {
}

.textPre {
  color: var(--theme-color-text-faded);
  margin-bottom: 4px;
}

.note {
  color: var(--theme-color-warning);
  font-size: 0.8rem;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
}

.text:first-of-type {
  margin-top: 8px;
}
