$content_width: 800px;
.results {
  width: 100%;
  max-width: $content_width;
}

.filter {
  margin-top: 16px;
  width: 100%;
  max-width: $content_width;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.card {
  width: 100%;
  margin-bottom: 8px;
}

.headline {
  font-size: 2rem;
  margin-bottom: 0;
}

.subheadline {
  max-width: $content_width;
  text-align: center;
}
