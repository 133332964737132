.overlay {
  background: rgba(0, 0, 0, 0.7);
  /* prevent flicker */
  animation-fill-mode: forwards !important;
}

.container {
  padding: 16px;
  /* prevent flicker */
  animation-fill-mode: forwards !important;
}

.modal {
  background-color: var(--theme-color-layer);
  border-radius: var(--theme-border-radius);
  width: 100%;
  padding: 0;
  margin: 16px auto;
  max-width: clamp(300px, calc(100vw - 32px), 700px);
  /* prevent flicker */
  animation-fill-mode: forwards !important;
}

.titleContainer {
  padding: 16px 16px 0 16px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px 64px 4px 0;
}

.subtitle {
  color: var(--theme-color-text-faded);
  margin: 0px 64px 0px 0;
}

.closeButton {
  width: 42px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:is(:hover, :active) {
  background: var(--theme-color-selected);
  border-radius: var(--theme-border-radius);
}

.closeIcon {
  font-size: 18px;
  fill: var(--theme-color-text-faded);
}

.content {
  width: 100%;
  padding: 16px;
  overflow-y: auto;
}

.actions {
  padding: 16px;
  background: var(--theme-color-layer-alt);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions > * {
  margin-left: 16px;
}
