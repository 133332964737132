@import "app/style/shared";
@import "app/style/mixins";
.root {
  // margin-bottom: 8px;
  border: 1px solid transparent;
}

.active {
  @include hover-primary;
  /* background-color: var(--theme-color-primary);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end)); */
  // color: white;
}

.root:is(:hover, :active) {
  @include hover-primary;
  border-color: var(--theme-color-primary-transparent-40);
  /* box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40); */
}

.root:is(:active, :focus) {
  border-color: var(--theme-color-primary-transparent-40);
  box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40);
}

.content {
  /* padding: 16px; */
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  /* border: 1px solid green; */
}

.actions {
  padding: 8px;
  display: flex;
  /* border: 1px solid blue; */
}

.iconActive {
  // color: white;
}

.listActive {
  color: var(--theme-color-text);
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: clip;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
  margin: 0;
  white-space: normal;
}

.subtitleIcon {
  margin-right: 8px;
}

.subtitle {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: var(--theme-color-text-faded);
  margin: 0;
}

.queryExcludes,
.queryFilter {
  font-size: 0.8rem;
}

.content > ul {
  padding: 0;
  list-style: none;
}

.filterItem {
  color: var(--theme-color-text-faded);
  font-size: 0.8rem;
}

.filterItemActive {
  // color: rgba(255, 255, 255, 0.8);
}
