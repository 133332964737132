@import "app/style/shared";
@import "app/style/mixins";
.content {
  padding: 0 8px;
  max-width: $width_xl;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 100px;
}

// .animatedRoute {
//   display: flex;
//   flex-direction: column;
//   gap: 8px;
// }

.feedbackButton {
  position: fixed;
  border-radius: var(--theme-border-radius);
  bottom: 20px;
  left: 20px;
  z-index: 1;
  width: 42px;
  height: 42px;
  padding: 0;
  min-width: 0;
  color: white;
  cursor: pointer;
  display: none;
  border: none;
  outline: none;
  background-color: var(--theme-color-secondary);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.24);
}

.paywall {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (min-width: 1380px) {
  .feedbackButton {
    display: block;
  }
}

.feedbackButton:is(:hover, :active) {
  background-color: var(--theme-color-secondary);
  opacity: 0.8;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.24);
}
