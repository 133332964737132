.root {
  display: flex;
  align-items: center;
}

.actions {
  margin: 8px;
}

.upload {
  // border: 1px solid red;
  width: 150px;
  height: 150px;
  border-radius: 100vh;
  position: relative;
}

.userImage {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 100vh;
}

.uploadOverlay {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 100vh;
}

.uploadedImage {
  width: 150px;
  height: 150px;
  border-radius: 100vh;
}

.uploadArea {
  position: absolute;
  background-color: var(--theme-color-selected);
  width: 150px;
  height: 150px;
  border-radius: 100vh;
}
