.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.dropParent {
  position: relative;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.dragChild {
  position: absolute;
  pointer-events: all;
  cursor: move;
}

.card {
  position: relative;
}

.header {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row-reverse;
}

.closeButton {
}

.capturePointer {
  pointer-events: all;
}

.dragging {
  opacity: 0;
}

.imageWrapper {
  border-radius: var(--theme-border-radius);
  overflow: hidden;
  // height: 150px;
  .image {
    border-radius: var(--theme-border-radius);
    overflow: hidden;
    height: auto;
  }
}
