@import "app/style/shared";

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
}

.content {
  max-width: 100%;
}

.note {
  margin-bottom: 32px;
  max-width: 800px;
}

.addButton {
  margin-top: 16px;
}
