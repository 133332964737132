.shake {
  animation: shake 400ms;
}

@keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  20%,
  60% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  40%,
  80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}

.pwResetLink {
  color: var(--theme-color-text-faded);
  display: block;
}

.loginPasswordWrapper {
  margin-bottom: 16px;
}

.signupHint {
  text-align: center;
}

.forgotPasswordLink {
  display: flex;
}
