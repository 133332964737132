.root {
  display: flex;
  padding: 0;
  width: 100%;
}

.content {
  padding: 16px 8px 16px 16px;
  flex-grow: 1;
  overflow: hidden;
}

.actions {
  padding: 8px 8px 8px 0;
}

.actionsVisible {
  animation: fade 0.3s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
