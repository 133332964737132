.colorPicker {
  margin-bottom: 8px;
  label {
    margin-bottom: 4px;
    display: block;
  }
}

.exportTitlePage {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 600px;
  overflow-x: auto;
  > button {
    min-width: 140px;
    padding: 8px;
    border: 2px solid transparent;
    border-radius: var(--theme-border-radius);

    > h4 {
      margin: 8px 0 16px 0;
    }

    &:hover,
    &.active {
      border: 2px solid var(--theme-color-primary);
      background: var(--theme-color-primary-transparent-40);
      cursor: pointer;
    }
    > img {
      max-width: 100%;
    }
  }
}
