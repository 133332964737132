
.form {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 40px;
}

.uspContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0;
}

.uspText {
  padding: 16px;
  flex: 1;
}

.uspImage {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  /* height: 150px; */
  width: 250px;
  /* flex-basis: 50%; */
  margin-bottom: 20px;
}

.subheadline {
  font-weight: bold;
  margin: 16px 0;
}

.subtitle {
  font-weight: bold;
  margin-top: 48px;
}

.hint {
  margin: 16px 0 0 0;
  /* text-align: center; */
  font-size: 0.8rem;
}

.cta {
  font-size: 1.2em;
  border: none;
  outline: none;
  max-width: 500px;
  padding: 16px 32px;
  font-weight: bold;
  border-radius: var(--theme-border-radius);
  cursor: pointer;
  color: white;
  background-color: var(--theme-color-secondary);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end));
}

.card {
  width: 100%;
}
