@import "app/style/shared";
@import "app/style/mixins";
.addButton {
  width: 100%;
  margin-top: 16px;
}

.addCard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    @include hover-primary;
  }
}
