@import "app/style/shared";
@import "app/style/mixins";
.root {
  margin-bottom: 8px;
  width: 100%;
}

.root.ignored {
  opacity: 0.5;
}

.noBg {
  background-color: transparent;
  background: none;
  box-shadow: none;
}

.content {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.hasControls {
  > div {
    padding-left: 36px;
  }
}

.canSelect {
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &.isSelected {
    @include hover-primary;
  }
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 32px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: var(--theme-border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--theme-color-primary-transparent-5);
}

.detailWrapper {
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: var(--theme-border-radius);
  overflow: hidden;
}

@media (min-width: 576px) {
  .mainContent {
    display: flex;
  }
}

.contentRight {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contentRightPadded {
  padding: 8px;
}

.owner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.owner > button {
  margin: 0 8px 4px 0;
  text-align: start;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.applicant {
  color: var(--theme-color-text);
}

.representative {
  color: var(--theme-color-text-faded);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
}

.owner > button:is(:hover, :active) {
  /* color: var(--theme-color-primary); */
  text-decoration: underline;
}

.name {
  opacity: 0.9;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 8px 0;
  color: var(--theme-color-text);
  text-decoration: none;
}

.nameLink {
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
  color: var(--theme-color-text) !important;
}

.goodsClassWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
  justify-self: flex-start;
  color: var(--theme-color-text-faded);
  margin-top: 8px;
  /* border: 1px solid var(--theme-color-selected);
  border-radius: var(--theme-border-radius); */
  padding: 4px;
}

.text {
  max-width: 800px;
  /* border: 1px solid var(--theme-color-selected);
    border-radius: var(--theme-border-radius); */
  padding: 4px;
}

.imageDarkmode {
  filter: brightness(0.8);
}

.children {
  width: 100%;
}

/*

##################### COMPACT #####################

*/

.rootCompact {
  padding: 0;
  margin-bottom: 4px;
}

.controlsWrapperCompact {
  padding: 0 8px 0 8px;
}

.imageWrapperCompact {
  width: 100%;
  width: 100px;
  height: 100px;
  margin-right: 8px;
  border-radius: var(--theme-border-radius);
  overflow: hidden;
  flex-shrink: 0;
}

.contentCompact {
  padding: 4px 4px 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.descriptionCompact {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.nameCompact {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.goodsClassWrapperCompact {
  padding: 0;
  margin: 0;
}

/*

##################### IMAGE #####################

*/

@media (min-width: $width_xs) {
  .rootImage {
    width: 100%;
  }
  .imageWrapperImage {
    height: 300px;
  }
}

.rootImage {
  display: flex;
  flex-direction: column;
}

.descriptionImage {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  overflow: hidden;
  // min-height: 200px;
}

.contentImage {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.imageWrapperImage {
  width: 100%;
  border-top-left-radius: var(--theme-border-radius);
  border-top-right-radius: var(--theme-border-radius);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.actionsImage {
  display: flex;
}

.controlsWrapperImage {
  padding: 16px 0 0 0;
}
