@import "app/style/shared";
@import "app/style/mixins";
.root {
  position: relative;
}

.editor {
  border-radius: var(--theme-border-radius);
  border-color: var(--theme-color-layer-alt);
  height: auto;
  // transition: height 0.3s ease-in-out;
  // @include default-transitions;
  &.editable {
    border: 1px solid var(--theme-color-layer-alt);
    background: var(--theme-color-layer-alt);
  }
  .editorContent {
    padding: 8px;
    margin: 4px;
    // min-height: 100px;
    // background-color: var(--theme-color-background);
    border-radius: var(--theme-border-radius);
    *:focus-visible {
      outline: transparent !important;
    }
  }
  .editorActions {
    padding: 2px 2px 0 2px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .editorActionsMain {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }
    .editorActionsExtra {
      display: flex;
    }
    animation: fadeIn 0.3s normal forwards ease-in-out;
  }
  .editorButton {
    margin: 0 2px 2px 0;
    border-radius: var(--theme-border-radius);
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    color: var(--theme-color-text-faded);
    span {
      display: block;
    }
    &.editorButtonActive {
      background: var(--theme-color-selected);
    }
    &:hover {
      background: var(--theme-color-selected);
      color: var(--theme-color-primary);
    }
    .editorActionIcon {
      flex-grow: 1;
      display: block;
    }
    &.editorButtonWide {
      width: auto;
      &:hover {
        color: var(--theme-color-text-faded);
      }
    }
  }
}

.overlay {
  @include default-transitions;
  opacity: 0;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  background-color: var(--theme-color-layer-alt);
  transition: opacity 0.3s ease-in-out;
  border-radius: var(--theme-border-radius);
  border: 1px solid var(--theme-color-layer-alt);
  &:hover {
    opacity: 0.5;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.actions > * {
  margin-left: 16px;
}

.textDisplay {
  padding: 8px;
}

.loading {
  padding: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
