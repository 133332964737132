.root {
  width: 100%;
  h4 {
    color: var(--theme-color-text-faded);
    margin-bottom: 0;
  }
  p {
    color: var(--theme-color-text-faded);
    margin-top: 0;
    font-size: 0.8rem;
  }
}
