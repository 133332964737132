@import "app/style/shared";
@import "app/style/mixins";

.root {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 16px;
  width: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.element {
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  color: white;
  background-color: var(--theme-color-secondary);
  border-radius: var(--theme-border-radius);
  box-shadow: var(--theme-box-shadow-menu);
  transform: translate3d(0, 80px, 0);
  animation: moveIn 0.4s normal forwards ease-in-out;
}

.link,
.link:hover,
.link:active {
  color: white;
  text-align: center;
}

.title {
  // font-size: 1.2rem;
  font-weight: bold;
  // display: inline-block;
}

.button {
  margin-left: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--theme-color-selected);
  color: var(--theme-color-text-light);
  border-radius: 100vh;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover,
  &:focus {
    filter: brightness(1.1);
  }
}

@keyframes moveIn {
  0% {
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
