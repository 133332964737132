.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.infoText {
  font-weight: bold;
  margin-top: 20px;
}

.headline {
  font-size: 32px;
  font-weight: bold;
  margin: 0 0 16px 0;
}

.subheadline {
  margin: 0 0 20px 0;
}

.loginFormWrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 8px 8px 0 8px;
}

.label {
  margin-bottom: 5px;
  font-size: 16px;
  opacity: 0.5;
}

.input {
  margin-bottom: 40px;
  padding: 8px;
}

.loginPasswordWrapper {
  margin-bottom: 40px;
}

.loginPasswordWrapper > input {
  margin-bottom: 4px;
}

.pwResetLink {
  color: var(--theme-color-text-faded);
}

.button {
  margin-bottom: 8px;
}

.signup {
  display: flex;
  justify-content: center;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--theme-color-border);
  margin: 8px;
}

.separator {
  font-size: 18px;
  color: var(--theme-color-text-faded);
  width: 100%;
  margin: 24px 0 8px;
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--theme-color-border);
  margin-right: 8px;
}

.separator::after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--theme-color-border);
  margin-left: 8px;
}

.leftCard {
  background-color: var(--theme-color-primary);
  color: white;
}

@media (max-width: 768px) {
  .leftCard {
    display: none;
  }
  .login {
    width: 100%;
    max-width: 100%;
  }
}

.legalBox {
  margin-bottom: 20px;
  opacity: 0.9;
  font-size: 16px;
}

.inlineModalLink {
  color: var(--theme-color-primary);
  cursor: pointer;
}

.inlineModalLink:hover {
  opacity: 0.8;
}

.socialLogins {
  padding: 20px 0;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--theme-color-layer);
  display: flex;
  justify-content: center;
  align-items: center;
}
