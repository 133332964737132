@import "app/style/shared";
@import "app/style/mixins";

.root {
  width: 100%;
  border: 2px dashed var(--theme-color-border);
  padding: 32px 16px;
  border-radius: var(--theme-border-radius);
  color: var(--theme-color-text-faded);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @include default-transitions;
}

.root:hover,
.root.active {
  color: var(--theme-color-primary);
  border-color: var(--theme-color-primary);
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin: 8px 0 0 0;
    text-align: center;
  }
}
