@import "app/style/shared";
@import "app/style/mixins";

.highlight {
  color: var(--theme-color-primary);
}

.tmcard {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header {
  margin-bottom: 8px;
}

.tmcardbuttons {
  border-top: 1px solid var(--theme-color-background-transparent);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.tmcardbuttons > * {
  margin-right: 8px;
}

.trademarkWrapper {
  width: 100%;
  position: relative;
}

.buttonGroupWrapper {
  background-color: var(--theme-color-layer);
  margin-left: 16px;
  margin-top: -16px;
  padding-top: 8px;
  z-index: 10;
  width: 160px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: var(--theme-box-shadow);
  border-radius: var(--theme-border-radius);
}

.collisionFlagButton {
  background: none;
  outline: none;
  border: none;
  border-radius: var(--theme-border-radius);
  padding: 8px;
  flex-basis: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  cursor: pointer;
  .collisionFlagButtonIcon {
    border-radius: 10000px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theme-color-background);
  }
  &.problematic {
    &:hover,
    &.active {
      background-color: var(--theme-color-negative-transparent);
    }
    .collisionFlagButtonIcon {
      background-color: var(--theme-color-negative);
    }
  }
  &.unproblematic {
    &:hover,
    &.active {
      background-color: var(--theme-color-gray-transparent);
    }
    .collisionFlagButtonIcon {
      background-color: var(--theme-color-gray);
    }
  }
  &.unknown {
    &:hover,
    &.active {
      background-color: var(--theme-color-primary-transparent-80);
    }
    .collisionFlagButtonIcon {
      background-color: var(--theme-color-primary);
    }
  }
}

.monitoringReportActions {
  display: flex;
}

.monitoringReportActions > * {
  margin-right: 8px;
}

.resetIcon > path {
  /* TODO: fix this hacky override */
  stroke: var(--theme-color-text);
}

.commentButtonWrapper {
  display: flex;
  align-items: center;
}

.commentButton {
  margin: 0 2px 2px 0;
  border-radius: var(--theme-border-radius-small);
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  color: var(--theme-color-text-faded);
  &:hover {
    background: var(--theme-color-selected);
    color: var(--theme-color-primary);
  }
}

.datawrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  > * {
    flex: 1;
    min-width: 300px;
  }
}

@media (min-width: $width_xs) {
  .datawrapper {
    flex-direction: column;
  }
}

@media (min-width: $width_md) {
  .datawrapper {
    flex-direction: row;
    > :first-child {
      margin-right: 8px;
    }
    > :nth-child(2) {
      margin-left: 8px;
    }
  }
}
