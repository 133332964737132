.firstCard {
  margin-top: 8px;
}

.companyLogo {
  max-width: 100%;
}

.sessionListItem {
  display: flex;
  align-items: center;
}

.sessionListItemIcon {
  margin-right: 16px;
}

.input {
  margin-bottom: 16px;
}

.input > div {
  padding-bottom: 4px;
}

.input > small {
  color: var(--theme-color-text-faded);
}

.sessionListItemTime {
  color: var(--theme-color-text-faded);
  margin-left: 16px;
}

.card {
  width: 100%;
}
