.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 700px;
    padding: 16px 24px;
}

.noBg {
    background-color: none;
    background: none;
    border: none;
    box-shadow: none;
}

.fullWidth {
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
}

.image {
    max-width: 300px;
    width: 100%;
    margin: 16px;
}

.smallImage {
    max-width: 100px;
}

.heading {
    font-size: 1.5rem;
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    word-wrap: break-word;
    width: 100%;
}

.text {
    text-align: center;
    margin: 16px;
}

.emptyStateTutorial {
    display: flex;
    background: var(--theme-color-primary-transparent-20);
    border: 1px solid var(--theme-color-primary-transparent-20);
    padding: 4px;
    border-radius: var(--theme-border-radius);
    margin: 32px 0;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.emptyStateText {
    text-align: center;
    padding: 8px;
    flex: 1;
    min-width: 200px;
}