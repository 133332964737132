@import "../../../style/shared.scss";
@import "../../../style/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
}

.card {
  display: flex;
  align-items: center;
  height: 77px;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--theme-color-primary-transparent-40);
    // .link {
    //   color: var(--theme-color-primary);
    //   text-decoration: none;
    // }
    @include hover-primary;
  }
}

.cardContent {
  display: flex;
  align-items: center;
}

.image {
  margin-right: 8px;
  width: 40px;
  max-height: 40px;
}
