@mixin gradient-primary {
  background-color: var(--theme-color-primary);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end));
}

@mixin gradient-secondary {
  background-color: var(--theme-color-secondary);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end));
}

@mixin gradient-gray {
  background-color: var(--theme-color-gray);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end));
}

@mixin gradient-red {
  background-color: var(--theme-color-negative);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end));
}

@mixin hover-primary {
  border: 1px solid var(--theme-color-primary-transparent-40);
  background-color: var(--theme-color-primary);
  background-image: linear-gradient(145deg, var(--theme-hover-bg-image), var(--theme-hover-bg-image));
}

@mixin default-transitions {
  transition-duration: 0.2s;
  transition-property: background-color, color, border-color, height, width;
  transition-timing-function: ease-in-out;
}
