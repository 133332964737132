@import "app/style/shared";
$top_offset: 64px;
.overlay {
  background: rgba(0, 0, 0, 0.7);
  animation-fill-mode: forwards !important;
}

.container {
  padding: 16px;
  animation-fill-mode: forwards !important;
}

.modal {
  background: transparent;
  box-shadow: none;
  width: 100%;
  padding: 0;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  border-radius: var(--theme-border-radius);
  animation-fill-mode: forwards !important;
}

.titleContainer {
  padding: 16px 16px 0 16px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px 64px 4px 0;
}

.subtitle {
  color: var(--theme-color-text-faded);
  margin: 0px 64px 0px 0;
}

.hint {
  color: var(--theme-color-text-faded);
  margin: 0px 64px 0px 0;
  font-size: 0.9rem;
}

.closeButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}

.closeButton:is(:hover, :active) {
  background: var(--theme-color-selected);
  border-radius: var(--theme-border-radius);
}

.closeIcon {
  font-size: 18px;
  fill: var(--theme-color-text-faded);
}

.first {
  // width: 100px;
  flex-basis: 50%;
  padding: 16px;
  flex: 1;
  min-width: 300px;
  // background-color: var(--theme-color-layer);
  background-color: var(--theme-color-primary);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-start), var(--theme-gradient-bg-end));
  // border-radius: var(--theme-border-radius);
  color: white;
}

.second {
  min-width: 300px;
  // min-height: 500px;
  // width: 100px;
  // height: 400px;
  // flex: 1;
  padding: 16px;
  background-color: var(--theme-color-layer);
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.stepper {
  width: 100%;
  // height: 60px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pill {
  width: 10px;
  height: 5px;
  border-radius: var(--theme-border-radius);
  background-color: var(--theme-color-selected);
  margin: 0 4px;
}

.pillActive {
  background-color: var(--theme-color-primary);
}

.form {
  flex-grow: 1;
  margin: 8px 0 16px 0;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.buttons > button {
  margin: 8px;
}

.brand {
  display: flex;
  align-items: center;
}

.brandLogo {
  max-height: 48px;
  max-width: 48px;
  margin-right: 8px;
  // margin-left: 8px;
}

.brandName {
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
}

.upgradeNow {
  font-size: 1.4rem;
  font-weight: 600;
}

.subheadline {
  font-weight: 600;
  margin-bottom: 8px;
}

.textWrapper {
}

.tagline {
  font-weight: bold;
}

.subline {
  margin-top: 16px;
  font-size: 0.9em;
}

.pricingTable {
  padding: 16px 0;
}

@media (min-width: $width_sm) {
  .modal {
    flex-direction: row;
    overflow: visible;
  }
  .first {
    border-radius: var(--theme-border-radius);
    min-height: 600px;
    // border-top-right-radius: var(--theme-border-radius);
    // border-top-left-radius: var(--theme-border-radius);
    // border-bottom-right-radius: var(--theme-border-radius);
    // border-bottom-left-radius: var(--theme-border-radius);
    padding-right: $top_offset;
    transform: translate($top_offset, -$top_offset);
  }
  .second {
    border-radius: var(--theme-border-radius);
    z-index: 1;
    flex-basis: 50%;
    // margin-top: $top_offset;
    // margin-left: -$top_offset;
    // border-radius: var(--theme-border-radius);
  }
  .closeButton {
    z-index: 2;
    // margin-top: $top_offset;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
