.root {
  position: relative;
  .button {
    cursor: pointer;
    border-radius: var(--theme-border-radius);
    &:hover {
      background-color: var(--theme-color-selected);
    }
  }
}
