.emptyState {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.collisionPreview {
  display: flex;
  align-items: center;
  span {
    background-color: var(--theme-color-negative);
    padding: 2px 8px;
    border-radius: var(--theme-border-radius-small);
    font-weight: bold;
    margin-right: 8px;
    color: var(--theme-color-text-light);
  }
}
