.root {
  width: 100%;
  height: 100%;
  /* min-width: 150px;
    min-height: 150px; */
  border-radius: var(--theme-border-radius);
  overflow: hidden;
  user-select: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.root.hasError {
  background: var(--theme-color-layer-alt);
  cursor: initial;
}

.image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  overflow: hidden;
  border-radius: 4px;
}

.nonInteractive {
  cursor: initial;
  pointer-events: none;
}
