.root {
  color: #ffffff;
  border-radius: var(--theme-border-radius-small);
  padding: 3px 6px;
  line-height: 1;
  text-align: center;
  font-size: 0.8rem;
  height: 18px;
}

.noBg {
  background: none;
  padding: 3px 6px 3px 0;
  background-color: transparent !important;
  color: var(--theme-color-text-faded);
}
