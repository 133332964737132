.container {
  max-width: 400px;
}

.root {
  width: 100%;
  height: 100%;
}

.root .list:not(:last-child) {
  margin-bottom: 16px;
}

.list {
  display: flex;
  flex-direction: column;
}

.headline {
  /* color: var(--theme-color-text-faded); */
  margin: 0 0 8px 0;
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.list .text:not(:last-child) {
  margin-bottom: 8px;
}

.text {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  cursor: pointer;
  color: var(--theme-color-text-faded);
}

.text .name {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}

.text .count {
  align-self: unset;
  font-size: 0.8rem;
}

.text:hover .name,
.text.active .name {
  text-decoration: underline;
}

.text:hover {
  color: var(--theme-color-primary);
}

.text.active {
  color: var(--theme-color-primary);
}

.filterIcon,
.removeFilterIcon {
  align-self: center;
  display: none;
  margin-left: 4px;
}

.text.active .removeFilterIcon {
  display: initial;
}

.text:hover .filterIcon {
  display: initial;
}

.text.active:hover .removeFilterIcon {
  display: initial;
}

.list:hover .showFilter {
  display: initial;
}

.showFilter {
  display: none;
  cursor: pointer;
  color: var(--theme-color-primary);
}

.showFilter:hover {
  text-decoration: underline;
}

.more {
  cursor: pointer;
  color: var(--theme-color-primary);
}

.more:hover {
  color: var(--theme-color-primary);
  text-decoration: underline;
}

.map {
  border-radius: var(--theme-border-radius);
  overflow: hidden;
  box-shadow: var(--theme-box-shadow);
  border: 1px solid var(--theme-color-layer-border);
  margin-bottom: 8px;
  iframe {
    margin-bottom: -5px;
  }
}
