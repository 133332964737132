.root {
  max-height: 100%;
  overflow-y: scroll;
}

.titleRow {
  display: flex;
  align-items: center;
}

.id {
  font-size: 1.2rem;
}

.badge {
  background-color: #9575cd;
  margin-left: 8px;
}

.text:first-of-type {
  margin: 8px 0;
}

.list {
  margin: 8px 0;
  color: var(--theme-color-text-faded);
  padding-left: 0;
  list-style: none;
}
