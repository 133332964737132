@import "app/style/shared";
@import "app/style/mixins";
.header {
  display: flex;
  align-items: center;
  padding: 8px 8px 0 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  > * {
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
  backdrop-filter: blur(3px);
}

.wrapper {
  display: flex;
}

.aside {
  height: 100%;
  position: fixed;
  left: 0;
  top: 64px;
  margin-top: 8px;
  display: none;
  z-index: 100;
  visibility: hidden;
}

.headerSpacer {
  height: 64px;
  margin-bottom: 8px;
}

.asideSpacer {
  display: none;
  visibility: hidden;
  width: 64px;
  margin: 8px 0 8px 8px;
}

.nav {
  margin: 8px 0 8px 8px;
  width: 64px;
  padding: 4px 4px 1px 4px;
  border-radius: var(--theme-border-radius);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--theme-color-primary);
  box-shadow: var(--theme-box-shadow);
}

.main {
  flex: 1;
  max-width: 100%;
  min-width: 0;
}

@media (min-width: $width_md) {
  .aside {
    display: block;
    visibility: visible;
  }
  .asideSpacer {
    display: block;
    visibility: visible;
  }
}
