.root {
  position: relative;
  width: 100%;
  // border: 1px solid red;
}

.header {
  border-radius: var(--theme-border-radius);
  display: flex;
  align-items: center;
  padding: 4px;
  margin-bottom: 8px;
  justify-content: flex-start;
  overflow-x: auto;
}

.content {
}

.button {
  border: none;
  outline: none;
  background: none;
  border-radius: var(--theme-border-radius);
  margin: 2px;
  padding: 8px 16px;
  min-height: 44px;
  color: var(--theme-color-text);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-color-layer);
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  span {
    padding: 2px 8px;
    border-radius: var(--theme-border-radius-small);
    font-weight: bold;
    margin-left: 8px;
  }
  &.active {
    cursor: initial;
  }
  &.default {
    span {
      color: var(--theme-color-primary);
      //   background: var(--theme-color-gray);
    }
    &:hover,
    &.active {
      background: var(--theme-color-selected);
    }
  }
  &.gray {
    span {
      color: var(--theme-color-text-light);
      background: var(--theme-color-gray);
    }
    &:hover,
    &.active {
      color: var(--theme-color-text-light);
      background: var(--theme-color-gray);
    }
  }
  &.primary {
    span {
      color: var(--theme-color-text-light);
      background: var(--theme-color-primary);
    }
    &:hover,
    &.active {
      color: var(--theme-color-text-light);
      background: var(--theme-color-primary);
    }
  }
  &.danger {
    span {
      color: var(--theme-color-text-light);
      background: var(--theme-color-negative);
    }
    &:hover,
    &.active {
      color: var(--theme-color-text-light);
      background: var(--theme-color-negative);
    }
  }
  &.secondary {
    span {
      color: var(--theme-color-text-light);
      background: var(--theme-color-secondary);
    }
    &:hover,
    &.active {
      color: var(--theme-color-text-light);
      background: var(--theme-color-secondary);
    }
  }
}

.button:is(:hover, :active) {
  background: var(--theme-color-selected);
}

.buttonActive {
  cursor: initial;
  color: var(--theme-color-primary);
  background: var(--theme-color-layer);
  box-shadow: var(--theme-box-shadow);
}

.buttonActive:is(:hover, :focus, :active) {
  background: var(--theme-color-layer);
}
