.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 0 0;
}

.page {
  padding: 8px;
  font-size: 1.1rem;
  color: var(--theme-color-text);
  cursor: pointer;

  &:hover {
    color: var(--theme-color-primary);
  }

  &.active {
    cursor: initial;
    color: var(--theme-color-primary);
  }
}

.nextButton,
.prevButton {
  font-size: 1.1rem;
}

.nextButton {
  margin-left: 16px;
}
.prevButton {
  margin-right: 16px;
}
