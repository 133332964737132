@import "../../style/shared.scss";
@import "../../style/mixins.scss";

.firstCard {
  margin-top: 8px;
}

.root {
  width: 100%;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 16px;
}

.imageWrapper {
  max-width: 100%;
}

.image {
  margin: 20px 0 0 0;
  width: 400px;
  max-width: 100%;
}

.headline {
  /* font-size: 18px; */
  font-size: 1.2rem;
  margin: 0 0 8px 0;
  font-weight: 700;
}

.viennaClass,
.niceClass {
  margin: 0 0 8px 0;
}

.subheadline {
  color: var(--theme-color-text-faded);
  margin: 0 0 8px 0;
}

.activitiesWrapper {
  width: 100%;
  overflow-x: scroll;
}

.activities {
  width: 100%;
}

.activities {
  width: 100%;
  border-spacing: 0 0;
  * {
    white-space: nowrap;
  }
  thead {
    background: var(--theme-color-layer-alt);
  }
  th:first-child {
    border-top-left-radius: var(--theme-border-radius-small);
    border-bottom-left-radius: var(--theme-border-radius-small);
  }
  th:last-child {
    border-top-right-radius: var(--theme-border-radius-small);
    border-bottom-right-radius: var(--theme-border-radius-small);
  }
  th {
    font-size: 1.1rem;
    padding: 8px 4px;
    font-weight: normal;
    // color: var(--theme-color-text-faded);
    text-align: left;
  }
  td {
    padding: 4px;
    // color: var(--theme-color-text-faded);
  }
  tr:hover {
    padding: 4px;
    td {
      color: var(--theme-color-text) !important;
    }
  }
  tr:nth-child(even) {
    background: var(--theme-color-layer-alt);
  }
  td:first-child {
    border-top-left-radius: var(--theme-border-radius-small);
    border-bottom-left-radius: var(--theme-border-radius-small);
  }
  td:last-child {
    border-top-right-radius: var(--theme-border-radius-small);
    border-bottom-right-radius: var(--theme-border-radius-small);
  }
}

.debug {
  margin-top: 48px;
}

.datawrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  > * {
    flex: 1;
    min-width: 300px;
  }
}

@media (min-width: $width_xs) {
  .datawrapper {
    flex-direction: column;
  }
}

@media (min-width: $width_md) {
  .datawrapper {
    flex-direction: row;
    > :first-child {
      margin-right: 8px;
    }
    > :nth-child(2) {
      margin-left: 8px;
    }
  }
}
