.list {
  padding: 0;
  margin: 0;
}

.item {
  list-style: none;
  font-size: 1em;
  display: flex;
  align-items: center;
  margin-top: 16px;
  line-height: 1;
}

.checkmark {
  margin-right: 8px;
}
