.firstCard {
  margin-top: 8px;
}

.premiumOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--theme-color-layer-transparent);
  border-radius: var(--theme-border-radius);
  z-index: 1000;
  padding: 32px;
}

.overlayTitle {
  font-size: 2rem;
  margin: 0;
}

.overlayText {
  text-align: center;
  max-width: 600px;
  margin-bottom: 32px;
}

.logoUpload {
  margin-bottom: 16px;
}

.logoUpload > label {
  font-size: 0.8rem;
  color: var(--theme-color-text-faded);
  display: block;
  margin-bottom: 8px;
}

.colorPicker {
  margin-bottom: 8px;
  label {
    margin-bottom: 4px;
    display: block;
  }
}

.companyLogo {
  max-width: 100%;
}

.workspaceName {
  font-size: 2rem;
}

.exportTitlePage {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 600px;
  overflow-x: auto;
  > button {
    min-width: 140px;
    padding: 8px;
    border: 2px solid transparent;
    border-radius: var(--theme-border-radius);

    > h4 {
      margin: 8px 0 16px 0;
    }

    &:hover,
    &.active {
      border: 2px solid var(--theme-color-primary);
      background: var(--theme-color-primary-transparent-40);
      cursor: pointer;
    }
    > img {
      max-width: 100%;
    }
  }
}
