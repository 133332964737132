@import "app/style/shared";
@import "app/style/mixins";
.root {
  padding: 8px 0;
}

.input {
  background-color: var(--theme-color-layer-alt);
  color: var(--theme-color-text);
  border: 1px solid var(--theme-color-selected);
  border-radius: var(--theme-border-radius-small);
  padding: 8px 8px;
  width: 100%;
  @include default-transitions;

  &:active,
  &:hover {
    border: 1px solid var(--theme-color-primary-transparent-80);
  }

  &:is(:focus) {
    /* border: 1px solid var(--theme-color-primary-transparent-40); */
    /* box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40); */
    /* -webkit-box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40); */
    -webkit-appearance: none;
  }

  &.inputInvalid {
    border: 1px solid var(--theme-color-negative-transparent) !important;
    outline: 1px solid var(--theme-color-negative-transparent) !important;
    box-shadow: 0 0 0 0.25rem var(--theme-color-negative-transparent) !important;
    -webkit-box-shadow: 0 0 0 0.25rem var(--theme-color-negative-transparent) !important;
    -webkit-appearance: none;
  }
}

.label {
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: var(--theme-color-text-faded);
  display: block;
}

.labelInvalid {
  color: var(--theme-color-negative);
}
