.wrapper {
    .invoice {
        display: flex;
        align-items: center;
        border-radius: var(--theme-border-radius);
        padding: 4px 8px;
        margin-bottom: 2px;
        &.invoiceBg {
            background-color: var(--theme-color-layer-border);
        }
        &:hover {
            background-color: var(--theme-color-selected);
        }
        .invoiceTitle {
            flex-grow: 1;
        }
        h4 {
            margin: 0;
        }
        span {
            color: var(--theme-color-text-faded);
            font-size: 0.8rem;
        }
        a {
            padding: 4px 8px;
        }
    }
}