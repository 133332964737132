.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.filebutton {
  width: 100%;
  border: 2px solid transparent;
  border-radius: var(--theme-border-radius);
  color: var(--theme-color-text);
  margin: 8px;
  // max-width: 80px;
  // min-height: 100px;
  max-width: 150px;
  // min-width: 100px;
  background: transparent;
}

.filebutton:is(:hover, :active, :focus):not([disabled]) {
  border: 2px solid var(--theme-color-primary);
  cursor: pointer;
}

.buttonActive {
  border: 2px solid var(--theme-color-primary);
  background: var(--theme-color-primary-transparent-40);
}

.icon {
  margin: 16px 16px 8px 16px;
}
