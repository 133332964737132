.list {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 2px;
}

.listItem {
  margin: 2px;
  padding: 8px 8px;
  word-wrap: normal;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: var(--theme-border-radius-small);
  &:hover,
  &.listItemActive {
    background: var(--theme-color-selected);
  }
}

.icon {
  margin-right: 8px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-size: 0.8rem;
  color: var(--theme-color-text-faded);
}
