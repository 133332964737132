@import "app/style/shared";

.filterWrapper {
  display: flex;
  gap: 8px;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.quickSelects {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filtersEnabled {
  color: rgb(241, 241, 241);
  background-color: var(--theme-color-primary);
}

.filtersEnabled:is(:active, :hover) {
  color: rgb(241, 241, 241);
  background-color: var(--theme-color-primary);
  opacity: 0.9;
}

.headline {
  font-weight: bold;
  margin: 0;
  color: var(--theme-color-text);
}

.subheadline {
  color: var(--theme-color-text-faded);
  margin: 0 0 4px 0;
}

.clearButton {
  margin-bottom: 24px;
}

.speedDial {
  background-color: var(--theme-color-layer-alt);
  border-radius: var(--theme-border-radius-small);
  padding: 8px;
}

.speedDialGroup {
  margin-bottom: 16px;
}
.speedDialButton {
  font-weight: normal;
}

.filterButton {
  color: var(--theme-color-primary);
  background-color: var(--theme-color-layer);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: var(--theme-border-radius);
  border: 1px solid var(--theme-color-layer-border);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  &:hover,
  :focus {
    filter: brightness(1.1);
  }

  > .buttonIcon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}

.menu {
  width: 300px;
}

.filtersMenu {
  width: 600px;
}

@media (min-width: $width_xs) {
  .filterWrapper {
    display: flex;
    flex-direction: column;

    .dropdowns {
      min-width: 300px;
      max-width: 500px;
      width: 100%;
    }

    .quickSelects {
      min-width: 240px;
    }
  }
}

@media (min-width: $width_sm) {
  .filterWrapper {
    flex-direction: row;
  }
  .menu {
    width: 410px;
  }
}
