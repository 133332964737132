.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--theme-color-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loadingOverlayLogo {
  max-width: 100px;
  width: 100%;
  margin-bottom: 16px;
}

.loadingOverlayTitle {
  font-size: 2rem;
  font-weight: bold;
}
