@import "app/style/shared";
@import "app/style/mixins";
.root {
    width: 100%;
    position: relative;
    border-radius: 100vh;
    *:focus-visible {
        outline: transparent;
    }
}

.focused {
    box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40);
}

.select>select {
    box-shadow: none;
    border: none;
    padding-right: 8px;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    background: var(--theme-color-layer);
    border-radius: 100vh;
}

.searchByButtonWrapper {
    background: none;
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    cursor: pointer;
    color: var(--theme-color-text-faded);
}

.searchByButton {
    border-top-left-radius: 100vh;
    border-bottom-left-radius: 100vh;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    padding: 0 16px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color-text);
}

.searchByPopoverButton {
    height: 100%;
}

.searchByButton:hover {
    background: var(--theme-color-selected);
}

.searchByButtonActive {
    background: var(--theme-color-selected);
}

.inputWrapper {
    position: relative;
    flex-grow: 1;
    width: 100%;
}

.input {
    color: var(--theme-color-text);
    background: none;
    border: 0;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    line-height: 1;
    padding: 8px 24px 8px 16px;
    flex-grow: 1;
    border-radius: var(--theme-border-radius);
}

.input:disabled {
    color: var(--theme-color-text-faded);
}

.searchButton {
    padding: 0 16px 0 12px;
    background: none;
    border: none;
    outline: none;
    border-radius: 100vh;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    cursor: pointer;
    color: var(--theme-color-text-faded);
}

.searchButton:is(:hover, :active, :focus) {
    background: var(--theme-color-selected);
}

.clearSearchButton {
    position: absolute;
    background: none;
    right: 0;
    top: 0;
    bottom: -4px;
    outline: none;
    border: none;
    cursor: pointer;
    color: var(--theme-color-text-faded);
}

.clearSearchButton:is(:hover, :active, :focus) {
    color: var(--theme-color-primary);
}

.quickNav {
    position: absolute;
    padding: 16px;
    top: 50px;
    width: 100%;
    z-index: 10;
}

.quickNavList:not(:last-child) {
    margin-bottom: 24px;
}

.quickNavTitle {
    /* font-size: 21px; */
    font-weight: bold;
    margin-bottom: 8px;
}

.quickNavDetailLink {
    display: flex;
    align-items: center;
    margin: 2px 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--theme-color-text-faded);
    cursor: pointer;
    padding: 8px;
    border-radius: var(--theme-border-radius);
}

.quickNavDetailLink.active,
.quickNavDetailLink:is(:hover, :active, :focus) {
    color: var(--theme-color-text);
    text-decoration: none;
    background: var(--theme-color-selected);
}

.quickNavIcon {
    margin-right: 8px;
}

.quickNavImage {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    margin-left: -8px;
}