@import "app/style/shared";
@import "app/style/mixins";
.root {
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  // padding: 0 8px;
  height: 100%;
  max-width: $width_xl;
  margin: 0 auto;
}
