.firstCard {
  margin-top: 8px;
}

.section {
  display: flex;
  flex-direction: column;
}

.label {
  cursor: pointer;
}

.label:not(:last-child) {
  margin-bottom: 4px;
}

.checkbox {
  margin-top: 8px;
}
