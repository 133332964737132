.root {
  background-color: var(--theme-color-background-layer);
  border-radius: var(--theme-border-radius);
  // FIXME @codedrift
  // This was introduced with 7df9d8acfb7124ed8e00a10954532f8a877d8014
  // but has been removed because it breaks the popover.
  // Do we need this?
  // overflow: hidden;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow-x: auto !important;
  max-width: 100%;
}
