.light {
  --theme-color-primary: #008ffb;
  --theme-color-secondary: #fbb33a;
  
  --theme-color-text: #252525;
  --theme-color-text-disabled: rgba(47, 47, 47, 0.9);
  --theme-color-text-faded: #4d4d4d;
  --theme-color-background: #ffffff;
  --theme-color-background-layer: #f5f5f8;
  --theme-color-layer-border: #f0f0f0;
  --theme-color-layer: #ffffff;
  --theme-color-layer-alt: #f3f3f3;
  --theme-color-background-transparent: rgba(0, 0, 0, 0.05);
  --theme-color-selected: rgb(0, 0, 0, 0.1);
  --theme-color-border: #e0e0e0;
  --theme-gradient-bg-end: rgba(12, 7, 7, 0.1);
  --theme-gradient-bg-start: rgba(255, 255, 255, 0.2);
  --theme-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
  --theme-box-shadow-menu: 0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%);
  --theme-hover-bg-image: rgba(255, 255, 255, 0.9);
}

.dark {
  /* silightly darkended app colors */
  --theme-color-primary: #268ad5;
  --theme-color-secondary: #deac57;

  --theme-color-text: rgb(241, 241, 241);
  --theme-color-text-disabled: rgba(241, 241, 241, 0.9);
  --theme-color-text-faded: rgba(241, 241, 241, 0.7);
  --theme-color-background: #121212;
  --theme-color-background-layer: #212121;
  --theme-color-layer-border: #323232;
  --theme-color-layer: rgb(45, 45, 45);
  --theme-color-layer-alt: #3d3d3d;
  --theme-color-background-transparent: rgba(0, 0, 0, 0.3);
  --theme-color-selected: rgba(255, 255, 255, 0.1);
  --theme-color-border: #414141;
  --theme-gradient-bg-end: rgba(0, 0, 0, 0.2);
  --theme-gradient-bg-start: rgba(0, 0, 0, 0.5);
  --theme-box-shadow: 0 0.25em 0.25em 0 rgb(0 0 0 / 10%);
  --theme-box-shadow-menu: 0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%);
  --theme-hover-bg-image: rgba(0, 0, 0, 0.7);
}

:root {
  --theme-color-white: #ffffff;
  --theme-color-black: #000000;


  --theme-color-primary-transparent-80: rgba(0, 143, 251, 0.8);
  --theme-color-primary-transparent-40: rgba(0, 143, 251, 0.4);
  --theme-color-primary-transparent-20: rgba(0, 143, 251, 0.2);
  --theme-color-primary-transparent-5: rgba(0, 143, 251, 0.05);

  --theme-box-shadow-primary: 0 0.25em 0.25em 0 rgb(0 143 251 / 50%);
  --theme-color-text-light: rgb(241, 241, 241);
  --theme-color-gray: #a0a0a0;
  --theme-color-gray-transparent: rgba(160, 160, 160, 0.4);
  /* Colors for info bars, toasts etc */
  --theme-color-info: #008ffb;
  --theme-color-positive: #00c851;
  --theme-color-positive-transparent: rgba(0, 200, 81, 0.4);
  --theme-color-positive-alt: #00b147;
  --theme-color-negative: #f44336;
  --theme-color-negative-alt: #d43a2f;
  --theme-color-negative-transparent: rgba(244, 67, 54, 0.4);
  --theme-color-warning: #f3b244;
  --theme-color-warning-transparent: rgba(255, 187, 51, 0.4);
  --theme-color-warning-alt: #e6a82e;
  --theme-border-radius: 8px;
  --theme-border-radius-small: 4px;
  --theme-breakpoint-wide: 1160px;
  --theme-font-sans: "DM Sans", Sans-Serif;
}
