.root {
  background: var(--theme-color-layer);
  position: relative;
  border-radius: var(--theme-border-radius);
  padding: 8px;
  box-shadow: var(--theme-box-shadow);
  border: 1px solid var(--theme-color-layer-border);
  width: 100%;
}

.item {
  margin-bottom: 16px;
}

.title {
  margin: 0;
  margin-bottom: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.titleCompact {
  margin-bottom: 0;
}

.titleMain {
  flex: 1;
  & > div:first-child {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  & > div:nth-child(2) {
    margin: 0;
    padding: 0;
    color: var(--theme-color-text-faded);
    font-weight: 400;
  }
}

.content {
  width: 100%;
  padding: 8px;
}

.actions {
  display: flex;
  align-items: center;
  margin: 16px 0 8px 0;
  flex-wrap: wrap;
  padding: 0 8px;
}

.actions > * {
  margin-right: 16px;
  margin-top: 8px;
}

.titleHasAction {
  cursor: pointer;
}

.titleHasIcon {
  padding-right: 8px;
}

.section {
  // border: 1px solid red;
  margin-bottom: 16px;
  // border-radius: var(--theme-border-radius);
  padding: 8px;
  // background: var(--theme-color-layer-border);
  .sectionTitle {
    display: flex;
    justify-content: space-between;
    // border: 1px solid blue;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--theme-color-layer-border);
    margin: 0 0 8px 0;
    h4 {
      margin: 0;
      color: var(--theme-color-text-faded);
    }
    p {
      margin: 0;
      color: var(--theme-color-text-faded);
    }
  }
}
