body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-color-background);
  color: var(--theme-color-text);
  font-family: var(--theme-font-sans);
}

body,
html,
#root {
  width: 100%;
  min-height: 100%;
  outline: none !important;
}

body:focus-visible,
html:focus-visible,
#root:focus-visible {
  outline: none !important;
  border: none;
}

html {
  overflow-y: scroll;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  scrollbar-width: thin;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* USED TO SUPPRESS STRIPE IFRAME BLOCKING USER INPUT */

/* iframe {
  z-index: -10000 !important;
} */

input:focus-visible,
textarea:focus-visible,
a:focus-visible,
button:focus-visible {
  outline: 2px solid var(--theme-color-primary-transparent-40);
}

a {
  color: var(--theme-color-primary);
  text-decoration: none;
}

a:hover {
  color: var(--theme-color-primary);
  text-decoration: underline;
}

p {
  line-height: 1.3;
  /* word-break: break-all; */
  white-space: normal;
  word-wrap: break-word;
  margin: 8px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* word-break: break-all; */
  white-space: normal;
  word-wrap: break-word;
}

ul,
ol,
li {
  /* word-break: break-all; */
  white-space: normal;
  word-wrap: break-word;
}

::selection {
  color: white !important;
  background: var(--theme-color-primary-transparent-80);
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: white !important;
  background: var(--theme-color-primary-transparent-80);
  /* Gecko Browsers */
}

@media print {
  html,
  body {
    display: none;
    /* hide whole page */
  }
}
