@import "app/style/shared";
@import "app/style/mixins";

.wrapper {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  width: 100%;
}

.card {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
  }
  .titleIcon {
    margin-right: 8px;
  }
  h4 {
    font-size: 1.4rem;
    margin: 4px 0;
  }
  p {
    color: var(--theme-color-text-faded);
    text-overflow: ellipsis;
    margin: 4px 0;
  }
  .link {
    text-decoration: none;
    color: var(--theme-color-text);
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    &:hover {
      color: var(--theme-color-primary);
      text-decoration: none;
    }
  }
  .children {
    padding: 8px 16px;
    width: 100%;
    flex-grow: 1;
  }
  .bottom {
    padding: 8px 16px;
    border-top: 1px solid var(--theme-color-border);
    color: var(--theme-color-text-faded);
    font-size: 0.8rem;
  }
}

@media (min-width: $width_xs) {
  .item {
    max-width: 100%;
  }
}

@media (min-width: $width_sm) {
  .item {
    max-width: 300px;
  }
}
