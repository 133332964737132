.form {
  display: flex;
  flex-direction: column;

  > p {
    margin-bottom: 16px;
  }
}

.textarea {
  margin: 16px 0 32px 0;
}

.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  > button {
    font-size: 0.8rem;
    padding: 0.25em 0.5em;
    background-color: var(--theme-color-primary);
    border-radius: var(--theme-border-radius);
    outline: none;
    border: none;
    color: var(--theme-color-text-light);
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
  }
}
