.root {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin-top: 8px;
  text-align: center;
  font-size: 0.9em;
  color: var(--theme-color-text-faded);
}
