@import "app/style/shared";
@import "app/style/mixins";
.button {
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: var(--theme-border-radius);
  color: white;
  background-color: var(--theme-color-primary);
}

.root:is(:hover, :active) {
  background-color: rgba(0, 0, 0, 0.2);
}

.root:is(:focus) {
  box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40);
}

.menu {
  width: 100%;
  min-width: 250px;
  max-height: calc(100vh - 90px);
  overflow: auto;
  z-index: 1001;
  border-radius: var(--theme-border-radius);
  // overflow: hidden;
  color: white;
  padding-bottom: 4px;
  padding: 4px;
  display: flex;
  flex-direction: column;
}

.menuSeparator {
  height: 1px;
  background-color: var(--theme-color-selected);
  width: 100%;
  margin-bottom: 4px;
}

.menuLink {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: white;
  border-radius: var(--theme-border-radius);
  .menuLinkSlim {
    justify-content: center;
  }
  .linkIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 56px;
    height: 56px;
  }
  &.isBeta {
    box-shadow: inset 0 0 0px 1px var(--theme-color-secondary);
  }
  &.isAdmin {
    box-shadow: inset 0 0 0px 1px var(--theme-color-negative);
  }
  &.isTestAccount{
    box-shadow: inset 0 0 0px 1px var(--theme-color-positive);
  }
  &:is(:hover, :active, :focus) {
    background: var(--theme-color-selected);
    text-decoration: none;
    cursor: pointer;
    color: white;
  }
  &.menuLinkActive {
    background: white;
    text-decoration: none;
    color: var(--theme-color-primary);
    .linkIcon {
      color: var(--theme-color-primary);
    }
  }
}

.popoverContent {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 8px;
}

.highlights {
  padding: 3px 8px 2px 8px;
  background-color: var(--theme-color-white);
  color: var(--theme-color-primary);
  border-radius: 100vh;
  line-height: 0.8rem;
  font-size: 0.8rem;
  margin-left: 8px;
}

.brandLogo {
  height: 36px;
  width: 36px;
  display: none;
}

.feedbackNavItem {
  background-color: var(--theme-color-secondary);
}

.feedbackNavItem:is(:hover, :active, :focus) {
  background-color: var(--theme-color-secondary);
  filter: brightness(1.1);
}

@media (min-width: $width_md) {
  .brandLogo {
    display: block;
  }
  .menuIcon {
    display: none;
  }
}
