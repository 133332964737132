.firstCard {
  margin-top: 8px;
}

.row {
  display: flex;
  align-items: center;
  p {
    padding: 8px;
  }
}

.card {
  width: 100%;
}

.preview {
  max-width: 500px;
}
