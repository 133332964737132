.success {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headline {
  text-align: center;
}

.subheadline {
  text-align: center;
}

.logout {
  width: 100%;
}
