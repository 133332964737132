@import "app/style/shared";
@import "app/style/mixins";
.root {
    background-color: var(--theme-color-layer-alt);
    color: var(--theme-color-text);
    width: 100%;
    height: 100%;
    resize: none;
    padding: 8px;
    border: 1px solid var(--theme-color-selected);
    border-radius: var(--theme-border-radius-small);
    @include default-transitions;
}

.root:is(:hover, :active) {
    border: 1px solid var(--theme-color-primary-transparent-80);
}

.root:focus-visible {
    border: 1px solid var(--theme-color-primary-transparent-40);
    box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40);
    -webkit-box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40);
    -webkit-appearance: none;
}