.root {
  /* width: 100%; */
  /* min-width: 250px; */
  position: relative;
}

.item {
  /* color: var(--theme-color-text); */
  display: flex;
  align-items: center;
  padding: 8px;
}

.item:is(:hover, :active) {
  background: var(--theme-color-selected);
}

.item.disabled {
  cursor: initial;
  opacity: 0.25;
}

.itemCheckbox {
  margin-right: 16px;
}

.itemText {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select {
  min-height: 32px !important;
  border-color: var(--theme-color-border) !important;
  background-color: var(--theme-color-layer-alt);
  color: var(--theme-color-text);
  border: 1px solid var(--theme-color-selected) !important;
  border-radius: var(--theme-border-radius-small) !important;
  padding: 8px 16px !important;
}

.selectHasItems {
  padding: 4px 16px 4px 8px !important;
}

.select:is(:hover, :active) {
  border-color: var(--theme-color-primary) !important;
  border: 1px solid var(--theme-color-primary-transparent-80);
}

.select:is(:focus) {
  box-shadow: 0 0 0 0.25rem var(--theme-color-primary-transparent-40);
}
