.legalBox {
  margin: 8px 0 16px 0;
  opacity: 0.9;
  font-size: 0.8rem;
}

.inlineModalLink {
  color: var(--theme-color-primary);
  cursor: pointer;
}

.inlineModalLink:hover {
  opacity: 0.8;
}
