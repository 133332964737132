@import "app/style/shared";
@import "app/style/mixins";
.button {
    color: var(--theme-color-text);
    background: none;
    // padding: 2px 12px;
    width: 36px;
    height: 36px;
    min-width: 0px;
    border-radius: var(--theme-border-radius);
    box-shadow: none;
    outline: none;
    border: none;
    cursor: pointer;
    user-select: none;
    @include default-transitions;
    &:hover,
     :active,
     :focus-visible {
        background: var(--theme-color-selected);
        box-shadow: none;
    }
    svg {
        user-select: none;
        margin: auto;
        vertical-align: middle;
        &:hover,
         :active,
         :focus-visible {
            background: none;
            box-shadow: none;
        }
    }
}