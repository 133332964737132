@import "app/style/shared";
.root {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--theme-color-background-layer);
}

.content {
    position: relative;
    width: 100%;
    padding: 16px;
}

.titleBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.titleBarAppLink {
    text-decoration: none;
}

.titleBarAppLink:hover {
    text-decoration: none;
}

.titleBarAppName {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.titleBarAppName>img {
    margin-right: 15px;
}

.title {
    margin: 0;
    font-size: 1.5rem;
    color: var(--theme-color-primary);
}

.titleBottom {
    font-size: 1.5rem;
    color: var(--theme-color-text-faded);
    z-index: 1;
    margin: 32px 0 0 0;
    opacity: 0.3;
}

@media (min-width: $width_xs) {
    .titleBar {
        padding: 16px;
    }
    .title {
        display: none;
    }
}

@media (min-width: $width_sm) {
    .title {
        display: block;
    }
    .titleBottom {
        display: none;
    }
    .bottomAppLink {
        display: none;
    }
}