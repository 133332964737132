@import "app/style/shared";
@import "app/style/mixins";
.root {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
  .title {
    color: var(--theme-color-text-faded);
  }
}

.headerActions {
  display: flex;
  align-items: center;
}

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.gridItem {
  margin: 4px;
}

.actions {
  display: flex;
}

@media (min-width: $width_xs) {
  .gridItem {
    width: 100%;
  }
}

@media (min-width: $width_sm) {
  .gridItem {
    flex-basis: 300px;
    flex: 1;
    min-width: 300px;
    max-width: 300px;
  }
}
