@import "app/style/shared";
@import "app/style/mixins";

.banner {
  padding: 16px;
  border: 1px solid var(--theme-color-warning);
  border-radius: var(--theme-border-radius);
  color: var(--theme-color-text-light);
  background-color: var(--theme-color-secondary);
  background-image: linear-gradient(145deg, var(--theme-gradient-bg-end), var(--theme-gradient-bg-start));
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.banner:after {
  animation: shine 10s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

.pretext {
  margin-right: 8px;
  white-space: nowrap;
  font-size: 1.3rem;
}

.icon {
  margin-right: 8px;
}

.okto {
  width: 2rem;
  height: 2rem;
}

.bannerLink {
  cursor: pointer;
  font-weight: 600;
  color: var(--theme-color-text-light);
  z-index: 1;
}

.bannerLink:is(:hover, :active, :focus) {
  color: var(--theme-color-text-light);
  text-decoration: underline;
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -20%;
    left: -20%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    animation-timing-function: ease;
  }
  20% {
    opacity: 0.1;
  }
  30% {
    opacity: 0;
    top: -15%;
    left: -15%;
    // animation-timing-function: ease-out;
  }
  // 15% {
  //     opacity: 0;
  // }
  // 17% {
  //     opacity: 0;
  // }
  100% {
    opacity: 0;
  }
}

@media (min-width: $width_xs) {
  .banner {
    flex-direction: column;
  }
  .bannerLink {
    text-align: center;
  }
  .pretext {
    margin-bottom: 8px;
  }
}

@media (min-width: $width_sm) {
  .banner {
    flex-direction: row;
  }
  .pretext {
    margin-bottom: 0;
    font-size: 1rem;
  }
}
