.root {
  background: var(--theme-color-background-transparent);
  border-radius: var(--theme-border-radius);
  padding: 6px;
  height: 44px;
}

.button {
  background: none;
  border-radius: var(--theme-border-radius);
  color: var(--theme-color-text-faded);
  outline: none;
  border: none;
  cursor: pointer;
  /* padding: 8px 0; */
  padding: 0;
}

.sortByButton {
  white-space: nowrap;
  color: var(--theme-color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-items: center;
  border-radius: var(--theme-border-radius);
  min-width: 32px;
  min-height: 32px;
}

.sortByButton:is(:focus, :hover, :active) {
  background: var(--theme-color-selected);
}

.buttonActive {
  background: var(--theme-color-background);
}
